.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.card-shadow{
box-shadow: 0 0 4px rgba(0, 0, 0, 0.18);
cursor: pointer;
}
.card-shadow:hover{
  background-color: rgba(0, 0, 0, 0.03);
}
.card-shadow:hover .md{
  color:orangered !important
 
}
.nav-item:hover .dropdown-menu{
  display:block!important
}
.team-member-details{
  background: rgba(255, 255, 9, 0.884);

}
.join-team {
  background-color: yellow;
  border: none;
  color: black;
  padding: 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 20px;
  margin: 4px 2px;
  border-radius: 50%;
}
.join-team:hover{
  background-color: black;
  color: yellow;
  
 
}